
<nav class="nav__container" fxLayout="row" fxLayoutAlign="center center">
    <div ngClass="nav" ngClass.lt-md="nav--xs" fxLayout="row" fxLayoutAlign="center center" >
        <div ngClass="nav__brand" ngClass.lt-md="nav__brand--xs">
            <a routerLink="">
                <h1>
                    R&R CONSULTING
               </h1>
            </a>
            
        </div>
        <div *ngIf="homeMenu" class="nav__sections" fxHide.lt-md="true" fxLayout="row" fxLayoutAlign="end center">
             <button class="nav__sections__button" (click)="scrollTo('quienes-somos')">
                 <p>¿Quiénes Somos?</p> 
             </button>
             <button class="nav__sections__button" (click)="scrollTo('why-us')">
                 <p>¿Por que nosotros?</p> 
             </button>
             <button class="nav__sections__button" (click)="scrollTo('services')">
                 <p>Servicios</p>
             </button>
             <button class="nav__sections__button" (click)="scrollTo('meet-her')">
                 <p>Contacto</p>
             </button>
        </div> 
        
        <button *ngIf="!homeMenu" class="nav__sections__button" routerLink="">
            <p>Inicio</p>
        </button>
    </div>
    <div *ngIf="homeMenu" ngClass="nav__dropdown" fxLayout="row" fxLayoutAlign="end center">
        <fa-icon ngClass="dropdown__button" fxShow.lt-md="true" fxHide.gt-sm="true" [icon]="faBars"></fa-icon>
        <div ngClass="nav__dropdown__content" ngClass="nav__dropdown__content--xs">
            <button (click)="scrollTo('quienes-somos')">
                <p>Quienes Somos</p> 
            </button>
            <button (click)="scrollTo('why-us')">
                <p>¿Por que nosotros?</p> 
            </button>
            <button (click)="scrollTo('services')">
                <p>Servicios</p>
            </button>
            <button (click)="scrollTo('meet-her')">
                <p>Contacto</p>
            </button>
        </div>
    </div>
</nav>