<section class="contact-us" fxLayout="column" fxLayoutAlign="center center" >
    <h1>Contacto</h1>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" >
        <div ngClass.lt-md="footer-col" fxLayout="column" fxLayoutAlign="center center" fxFlex.gt-sm="30vw">
            <h4>Dirección</h4>
            <p>
                Calle 14 #43A, Villa Aura, 
                Santo Domingo Oeste, R.D
            </p>
            <h4>Correo Electrónico</h4>
            <p>rrconsultingrd@gmail.com</p>
            <h4>Teléfono</h4>
            <p><a ngClass="phone-num" href="https://wa.me/18098544240">+1 (809) 854-4240</a></p>
        </div>
        <div ngClass.lt-md="footer-col" fxFlex.gt-sm="30vw" fxLayout="row" fxLayoutAlign="space-evenly center">
            <a href=""><fa-icon [icon]="faInstagramSquare" size="6x"></fa-icon></a>
            
            <a href="https://www.facebook.com/RRconsultingRD"><fa-icon [icon]="faFacebookSquare" size="6x"></fa-icon></a>
            
            <a href="https://wa.me/18098544240"><fa-icon [icon]="faWhatsappSquare" size="6x"></fa-icon></a>
            
        </div>
        <div ngClass.lt-md="footer-col" fxFlex.gt-sm="30vw">
            <h6>RRConsulting © <br>
                Todos los derechos reservados</h6>
        </div>
    </div>
</section>
