

<section class="why-us__container" fxLayout="column" fxLayoutAlign="center center">
    <div id="why-us"  style="position: absolute; top: -10rem; left: 0"></div>
    <div  
    ngClass="why-us__content" ngClass.xs="why-us__content--xs"
     fxLayout="column" fxLayoutAlign="center center">
        <h1>¿Por qué necesitas nuestra consultoría y asesoría?</h1>
        <p>La necesidad de contratar nuestros servicios existe por diversas razones, que por lo menos
            una usted identificara puede ser la causa que está impidiendo el crecimiento y la
            productividad de tu PYMES, aquí están listadas las 7 principales causas que pueden estar
            evitando el crecimiento de tu empresa:</p>
            <div 
            ngClass="why-us__row" ngClass=""
             fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="center center">
                <img ngClass="why-us__img" 
                ngClass.lt-lg="why-us__img why-us__img--md"
                ngClass.xs ="why-us__img why-us__img--xs "
                src="./assets/img/money-trees.jpg" alt="">
                <div class="why-us__list__container" fxLayout="column" fxLayoutAlign="center center">
                    <ol ngClass="why-us__list" 
                    ngClass.lt-lg="why-us__list why-us__list--md"
                    ngClass.xs="why-us__list why-us__list--xs"
                    >
                        <li>Tu empresa está creciendo mucho y rápido</li>
                        <li>Necesitas acelerar la gestión de un nuevo proyecto</li>
                        <li>Los clientes no están satisfechos con el servicio que ofreces</li>
                        <li>Descontentos entre empleados</li>
                        <li>Carencia de indicadores de gestión de empresa</li>
                        <li>Inexistencia de un plan estratégico</li>
                        <li>Pago de impuestos y necesidad de preparar estados financieros para el banco</li>
                    </ol>
                    <button class="why-us__button" routerLink="why-us">Leer más</button>
                </div>
            </div>
    </div>  
   
</section>
