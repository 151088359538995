<app-navbar [homeMenu]="false"></app-navbar>
<div fxLayout="column" fxLayoutAlign="center center">
    <div class="why-us__sub-container" fxLayout="column" fxLayoutAlign="center center">
        <img 
        ngClass="why-us__main-pic"
        ngClass.gt-md="why-us__main-pic why-us__main-pic--gt-md"
         src="../../assets/img/why-us-top-pic.jpg" alt="">
        <h1 ngClass="why-us__header">¿Por qué necesitas nuestra consultoría y asesoría?</h1>
        <h3>
            La necesidad de contratar nuestros servicios  existe por diversas razones, 
            que por lo menos usted  identificaras que una puede  ser la causa  que está 
            impidiendo el crecimiento y la productividad de tu Pymes  pero te comentaré  
            solo las 16 Principales a nuestro entender:
        </h3>
        <ol>
            <li>Tu empresa está creciendo mucho y  rápido</li>
            <li>Necesitas acelerar la gestión de un nuevo proyecto</li>
            <li>Los clientes no están satisfecho con el servicio que ofreces</li>
            <li>Descontentos entre los empleados</li>
            <li>Porque necesitas ahorrar dinero en proyecto,, impuestos ,reducción de gastos etc.</li>
            <li>
                Necesidad de crear políticas y procedimientos y estandarización de procesos. 
                Como son: Compras,  servicio a los clientes, suplidores, Manual de normas   
                de disciplina y consecuencias para los empleados, cobros, Inventarios, desarrollo de 
                líderes, activos fijos, conciliaciones entre otros.
            </li>
            <li>Pagar impuestos y preparar  estados financieros para el banco.</li>
            <li>
                Baja productividad por  utilizar prácticas inadecuadas en el proceso de reclutamiento,
                selección y contratación de personal poco calificado. No se capacita y tampoco se evalúa regularmente
                su desempeño
            </li>
            <li>
                Inexistencia de un plan estratégico: Este es la guía del rumbo que debe seguir la empresa de 3 a 5 años. 
                Si no sabes para dónde vas, entonces ya llegaste.
            </li>
            <li>Carencia de  indicadores de gestión  de empresa</li>
            <li>Bajo nivel de desarrollo en tecnología, producción y administración</li>
            <li>Metodología  inadecuada en los costos de producción y servicios.</li>
            <li>La contabilidad no se utiliza para la toma de decisiones</li>
            <li>
                Limitada atención a la parte financiera, como administrador, dueño, presidente de la empresa no dispone  de 
                suficiente tiempo para  no   proyectar una visión    de desarrollo y crecimiento  financiero a  mediano y 
                largo plazo  porque las tareas del día a día  te mantienen muy ocupado.
            </li>
            <li>Pobre poder de negociación al momento de adquirir bienes y servicios.</li>
            <li>Poca o baja supervisión, control   y mantenimiento  seguimiento al cuidado de los activos de la empresa.</li>
        </ol>
        <h2 ngClass="why-us__h2--red-text">Si te idéntificas por lo menos con  una de estas  necesidades, entonces indica que debes llamarnos de inmediato.</h2>
    </div>
</div>

