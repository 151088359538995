import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meet-her',
  templateUrl: './meet-her.component.html',
  styleUrls: ['./meet-her.component.css']
})
export class MeetHerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
