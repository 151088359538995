import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ServicesFullContentComponent } from './services-full-content/services-full-content.component';
import { WhyUsFullContentComponent } from './why-us-full-content/why-us-full-content.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path:'why-us', component: WhyUsFullContentComponent},
  {path: 'services', component: ServicesFullContentComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'disabled',scrollOffset: [0, 0] })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
