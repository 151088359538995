import { Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'RRConsulting';
  
  constructor() {}
  onActivate(event:any) {
    // window.scroll(0,0);
    document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
    
}
}
