import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HeroComponent } from './hero/hero.component';
import { WhyUsComponent } from './why-us/why-us.component';
import { ServicesComponent } from './services/services.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MeetHerComponent } from './meet-her/meet-her.component';
import { FooterComponent } from './footer/footer.component';
import { WhyUsFullContentComponent } from './why-us-full-content/why-us-full-content.component';
import { ServicesFullContentComponent } from './services-full-content/services-full-content.component';
import { HomeComponent } from './home/home.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HeroComponent,
    WhyUsComponent,
    ServicesComponent,
    MeetHerComponent,
    FooterComponent,
    WhyUsFullContentComponent,
    ServicesFullContentComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FlexLayoutModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
