<app-navbar [homeMenu]="false"></app-navbar>
<div ngClass="services__container" fxlayout="column" fxLayoutAlign="center center">
    <div ngClass="services__sub-container" fxLayout="column" fxLayoutAlign="center center">
        <img ngClass="mipymes-img" src="../../assets/img/asesoria-administrativa.jpg" alt="">
        <h1 ngClass="services-main-heading">Nuestros Servicios</h1>
        <div ngClass="servicios-mipymes">
            <h1 ngClass="category__heading" ngClass.xs="category__heading--xs">Consultoría y asesoría Administrativa para Mipymes:</h1>
            <div ngClass="servicios-mipymes__sub-container" fxLayout="column">
                <div ngClass="sub-service__column">
                    <h1 ngClass.xs="h1--xs">Planificación estratégica</h1>
                    <p>Depende de la necesidad y de los objetivos que tengan los clientes pero
                        el ABC de la planificar de manera estratégica  pueda ser  una empresa
                        se competitiva y eficiente son los siguientes enfoques:
                    </p>
                    <ul>
                        <li>Ventas y servicios al cliente</li>
                        <li>Desarrollo y capacitación recursos humanos</li>
                        <li>Innovación tecnología</li>
                        <li>Finanzas y crecimiento</li>
                        <li>Procesos internos, entre otros</li>
                    </ul>
                </div>
                <div ngClass="sub-service__column">
                    <h1 ngClass.xs="h1--xs">Gestión a las Unidades de negocios-servicios:</h1>
                    <p></p>
                    <ul>
                        <li>Auditoria y diagnostico por departamentos</li>
                        <li>Propuesta de valor</li>
                        <li>Implementación y Seguimiento </li>
                        <li>Evaluación y aplicación de mejoras continuas</li>
                    </ul>
                </div>
                <div ngClass="sub-service__column">
                    <h1 ngClass.xs="h1--xs">Creación y  Diseño de Manuales de  Normas, políticas y procedimientos</h1>
                    <p></p>
                    <ul>
                        <li>Servicio al cliente</li>
                        <li>Inventarios</li>
                        <li>Contabilidad</li>
                        <li>Créditos y cobros</li>
                        <li>Compras y Cuentas por pagar</li>
                        <li>Activos fijos</li>
                        <li>Manejo de Recursos humanos</li>
                        <li>Normas disciplinarias y Código de ética</li>
                        <li>Otros</li>
                    </ul>
                </div>
                <div ngClass="sub-service__column">
                    <h1 ngClass.xs="h1--xs">Gestión y manejo de personal</h1>
                    <p></p>
                    <ul>
                        <li>Elaboración de perfiles y descripción de puestos</li>
                        <li>Reclutamiento, selección y contratación</li>
                        <li>Capacitación e inducción</li>
                        <li>Evaluación e indicadores de desempeño</li>
                        <li>Desarrollo de líderes y mentoría</li>
                    </ul>
                </div>
            </div>
            <div fxLayout="column">
                <div ngClass="sub-service__column">
                    <h1 ngClass.xs="h1--xs">Gestión e implementación de proyectos:</h1>
                    <p></p>
                    <ul>
                        <li>Análisis de  viabilidad (Opción del cliente)</li>
                        <li>Inicio y ejecución del proyecto</li>
                        <li>Evaluación y cierre del proyecto</li>
                    </ul>
                </div>
                <div ngClass="sub-service__column">
                    <h1 ngClass.xs="h1--xs">Estandarización de los procesos</h1>
                    <p></p>
                    <ul>
                        <li>Diagnostico</li>
                        <li>Creación y diseño</li>
                        <li>Revisión Discusión</li>
                        <li>Aprobación</li>
                        <li>Implantación</li>
                        <li>Supervisión y evaluación</li>
                    </ul>
                </div>
                <div ngClass="sub-service__column">
                    <h1 ngClass.xs="h1--xs">Diseño de Indicadores de gestión y desempeño  (kpis)</h1>
                    <p></p>
                    <ul>
                        <li>Levantamiento</li>
                        <li>Implantación- Supervisión</li>
                    </ul>
                </div>
                <div ngClass="sub-service__column">
                    <h1 ngClass.xs="h1--xs">Saneamiento, control y actualización de los inventarios</h1>
                    <p></p>
                    <ul>
                        <li>Toma física</li>
                        <li>Plan de análisis, control y ejecución</li>
                        <li>Actualizaciones periódicas.</li>
                        <li>Informes y plan de mejoras</li>
                    </ul>
                </div>
            </div>
        </div>
        <img ngClass="servicios-financieros__img" src="../../assets/img/consultoria-financiera.jpg" alt="">
        <div ngClass="serivicios-financieros" fxLayout="column" fxLayoutAlign="center start">
                <h1 ngClass="category__heading" ngClass.xs="category__heading--xs">
                    Consultoría Financiera
                </h1>
                <div ngClass="servicios-financieros__subcontainer" fxLayout="column" >
                    <div ngClass="sub-service__column">
                        <h1 ngClass.xs="h1--xs">Tesorería</h1>
                        <ul>
                            <li> Ingresos</li>
                            <li>pagos</li>
                            <li>costos,</li>
                            <li>gastos</li>
                            <li>control del flujo efectivo.</li>
                        </ul>
                    </div>
                    <div ngClass="sub-service__column">
                        <h1 ngClass.xs="h1--xs">Presupuestos</h1>
                        <ul>
                            <li>Elaboración</li>
                            <li>ejecución</li>
                            <li>estadísticas.</li>
                        </ul>
                    </div>
                </div>
        </div>
        
        <img ngClass="adm-propiedades__img" src="../../assets/img/administracion-de-propiedades.jpg" alt="">
        <div ngClass="adm-propiedades">
            <h1 ngClass="category__heading" ngClass.xs="category__heading--xs">Administración de propiedades</h1>
            <div ngClass="sub-service__column">
                <h1 ngClass.xs="h1--xs">Gestión Ventas de inmuebles</h1>
                <ul>
                    <li>Levantamiento y análisis las condiciones del inmueble</li>
                    <li>Estudio de Mercado</li>
                    <li>Promoción eficiente e intensa por los medios digitales  de mayor alcance</li>
                    <li>nforme periódico del proceso de venta</li>
                    <li>Acompañamiento en la parte fiscal , legal y la depuración de la ley de  lavado de activos</li>
                </ul>
            </div>
            <div ngClass="sub-service__column">
                <h1 ngClass.xs="h1--xs">Gestión de Renta de inmuebles</h1>
                <ul>
                    <li>Rigurosa selección de nuevos inquilinos con un criterio con objetividad y razonabilidad</li>
                    <li>Análisis crediticios del inquilino-Data Crédito, laboral y familiar</li>
                    <li>Elaboración de contratos </li>
                    <li>Velamos por el cumplimiento de las cláusulas del contrato y la conservación de la propiedad</li>
                    <li>Negociación y renovación de contratos de arrendamiento</li>
                    <li>Pago al propietario puntualmente cada 30 días.</li>
                    <li>Coordinación y seguimiento a los mantenimientos  y reparaciones</li>
                    <li>Cobranzas</li>
                    <li>Plan de mantenimiento preventivo( trabajamos mínimo 3 cotizaciones)</li>
                    <li>Reporte mensual de las rentas puntual y transparente</li>
                </ul>
            </div>
        </div>
        <img ngClass="outsourcing__img" src="../../assets/img/outsourcing.jpg" alt="">
        <div ngClass="outsourcing" fxLayout="column" fxLayoutAlign="center center">
            <div ngClass="sub-service__column">
                <h1 ngClass="category__heading" ngClass.xs="category__heading--xs">Outsourcing</h1>
                <ul>
                    <li>Manejo de Redes sociales y marketing digital </li>
                    <li>Capacitaciones y Mentoría</li>
                    <li>Consultoría legal</li>
                </ul>
            </div>
        </div>
        <img ngClass="other-services__img" src="../../assets/img/other-services.jpg" alt="">
        <div ngClass="other-services" fxLayout="column" fxLayoutAlign="center center">
            <h1 ngClass="category__heading " ngClass.xs="category__heading--xs">Otros servicios diversos</h1>
            <div ngClass="sub-service__column">
                <h1 ngClass.xs="h1--xs">Gestión de compras de bienes y servicios:</h1>
                <ul>
                    <li>Requisición</li>
                    <li>Cotización</li>
                    <li>Varias Propuestas</li>
                    <li>Emisión de orden de compras</li>
                    <li>Recepción de bienes y servicios</li>
                </ul>
            </div>
            <div ngClass="sub-service__column">
                <h1 ngClass.xs="h1--xs">Consultoría en cooperativas ,ONGs e instituciones sin fines de lucro</h1>
                <ul>
                    <li>Creación</li>
                    <li>gestión administrativa</li>
                    <li>Gestión de negocios</li>
                    <li>Gestión social </li>
                    <li>Contabilidad y finanzas</li>
                    <li>Cumplimiento(Debida diligencia)</li>
                </ul>
            </div>
        </div>
    </div>
</div>
