<section ngClass="hero__container" fxLayout="column" fxLayoutAlign="center center">
    <div id="quienes-somos" style="position: absolute; top: -5rem; left: 0"></div>
    <div ngClass="hero__sub-container" fxLayout="column" fxLayoutAlign="center center" fxShow.gt-sm="true" fxHide.lt-md="true">
        <div  fxLayout="row" fxLayoutAlign="center center" >
            <img ngClass="hero__img background-blur" src="../../assets/img/logo_v2-removebg-preview.png" alt="RR Consulting Logo">
            <div>
                <div ngClass="hero__heading background-blur" fxLayout="column" fxLayoutAlign="center center">
                    <h2>
                        CONSULTORÍA ADMINISTRATIVA-CONTABLE,
                        IMPOSITVA Y FINANCIERA
                    </h2>
                </div>    
                <div ngClass="hero__desc background-blur" fxLayout="column" fxLayoutAlign="center center">
                    <h3>
                        ¿Quiénes Somos?
                    </h3>
                    <p>
                        Somos un equipo que trabaja con un alto nivel de compromiso, responsabilidad, con vasta
                        experiencia en sus respectivas áreas. Para brindarles a usted y a su PYME un
                        acompañamiento eficiente, con escucha activa, comunicación asertiva con soluciones
                        integrales y rentables para todas sus necesidades y problemas, minimizando gastos,
                        aumentando el ahorro y maximizando los ingresos mediante los servicios de consultoría y
                        asesoría administrativa-contable y financiera.
                    </p>
                </div>
            </div>
        </div>
        <div ngClass="goals__container" fxLayout="row" fxLayoutAlign="start start">
            <div ngClass="mision__container background-blur" fxLayout="column" fxLayoutAlign="center center">
                <h3>Misión</h3>
                <p>
                    Contribuir con el crecimiento y solidez de las empresas brindándoles soluciones integrales y
                    rentables a cada una de sus necesidades de manera eficiente, oportuna y confiable a través de
                    los servicios de consultoría administrativa-contable, impositiva financiera y de gestión.
                </p>
            </div>
            <div ngClass="vision__container background-blur" fxLayout="column" fxLayoutAlign="center center">
                <h3>Visión</h3>
                <p>
                    Ser la empresa número uno de referencia nacional a nivel de consultoría y asesoría
                    administrativo-contable, financiera y de gestión.
                </p>
            </div>
        </div>
    </div>
    <div fxHide.gt-sm ngClass="hero__sub-container--lt-md" fxLayout="column" fxLayoutAlign="center center">
        <img ngClass="hero__img--lt-md background-blur" src="../../assets/img/logo_v2-removebg-preview.png" alt="RR Consulting Logo">
        <div ngClass="container--lt-md background-blur" fxLayout="column" fxLayoutAlign="center center">
            <h2>
                CONSULTORÍA ADMINISTRATIVA-CONTABLE
                IMPOSITVA Y FINANCIERA
            </h2>
        </div>    
        <div ngClass="container--lt-md background-blur" fxLayout="column" fxLayoutAlign="center center">
            <h3>
                ¿Quienes Somos?
            </h3>
            <p>
                Somos un equipo que trabaja con un alto nivel de compromiso, responsabilidad, con basta 
                experiencia en sus respectivas areas. Para brindarles a usted y a su PYME un acompañamiento 
                eficiente, con escucha activa, comunicación asertiva con soluciones integrales y rentables para todas
                sus necesidades y problemas, minimizando gastos, aumentando el ahorro y maximizando los ingresos
                mediante los servicios de consultoría y asesoría administrativa-contable y financiera.
            </p>
        </div>
        <div ngClass="container--lt-md background-blur" fxLayout="column" fxLayoutAlign="center center">
            <h3>Mision</h3>
            <p>
                Contribuir con el crecimiento y solidez de las empresas brindandoles 
                soluciones integrales y rentables a cada una de sus necesidades de manera 
                eficiente, oportuna y confiable a traves de los servicios de consultoría 
                administrativa-contable, impositiva financiera y de gestion
            </p>
        </div>
        <div ngClass="container--lt-md background-blur" fxLayout="column" fxLayoutAlign="center center">
            <h3>Vision</h3>
            <p>
                Ser la empresa numero uno de referencia 
                nacional a nivel de consultoría y asesoría 
                administrativo-contable, financiera y 
                de gestion
            </p>
        </div>
    </div>
</section>