import { Component, OnInit } from '@angular/core';
import { faNetworkWired, faBuilding, faChartLine, faLaptopHouse } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  faNetworkWired = faNetworkWired;
  faBuilding = faBuilding;
  faChartLine = faChartLine;
  faLaptopHouse = faLaptopHouse
  constructor() { }

  ngOnInit(): void {
  }

}
