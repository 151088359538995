
<section class="services__container" fxLayout="row" fxLayoutAlign="center center">
    <div id="services" style="position: absolute; top: -10rem; left: 0"></div>
    <div class="services__container--inner-div" fxLayout="column" fxLayoutAlign="center center">
        <h1>Servicios</h1>
        <div class="services__row" fxLayout="row wrap" fxLayoutAlign="center start">
            <div class="services__column">
                <img class="services__img" src="./../../assets/img/asesoria-administrativa.jpg" 
                alt="consultoría y asesoría Administrativa para MIPYMES">
                <div class="icon-name__row" fxLayout="row" fxLayoutAlign="center center">
                    <fa-icon [icon]="faNetworkWired" size="2x"></fa-icon>
                    <h3>
                        Consultoría y asesoría Administrativa para MIPYMES
                    </h3>
                </div>
                <p>
                    Administración, contabilidad, finanzas e impuestos, planificación estratégica, gestión a las
                    unidades de negocios, servicios y más.
                </p>
            </div>
            <div class="services__column">
                <img class="services__img" src="./../../assets/img/administracion-de-propiedades.jpg" alt="administración de propiedades">
                <div class="icon-name__row" fxLayout="row" fxLayoutAlign="center center">
                    <fa-icon [icon]="faBuilding" size="2x"></fa-icon>
                    <h3>
                        Administración de propiedades
                    </h3>
                </div>
                <p>
                    Gestión ventas de inmuebles, gestión de renta de inmuebles, administración de propiedades
                    en Airbnb.
                </p>
            </div>
            <div class="services__column">
                <img class="services__img" src="./../../assets/img/consultoria-financiera.jpg" alt="consultoría Financiera">
                <div class="icon-name__row" fxLayout="row" fxLayoutAlign="center center">
                    <fa-icon [icon]="faChartLine" size="2x"></fa-icon>
                    <h3>
                        Consultoría Financiera
                    </h3>
                </div>
                <p>
                    Gestión de cobros, tesorería: ingresos, pagos, costos, gastos, control del flujo efectivo.
                    Presupuestos: elaboración, ejecución y estadísticas.
                </p>
            </div>
            <div class="services__column">
                <img class="services__img" src="./../../assets/img/outsourcing.jpg" alt="Outsourcing">
                <div class="icon-name__row" fxLayout="row" fxLayoutAlign="center center">
                    <fa-icon [icon]="faLaptopHouse" size="2x"></fa-icon>
                    <h3>
                        Outsourcing
                    </h3>
                </div>
                <p>
                    Manejo de redes sociales y marketing digital, capacitaciones, mentoría y consultoría legal.
                </p>
            </div>
            
        </div>
        <button ngClass="services__button" routerLink="services">Otros Servicios</button>
    </div>
    
</section>