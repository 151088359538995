import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT } from '@angular/common';
// declare const window: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  faBars = faBars;
  @Input() homeMenu: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
  }

  @HostListener('body:scroll', [])
  onWindowScroll() {
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // console.log('in here');
    
    if (number != 0) {
      // console.log(number);
      
        document.querySelectorAll('.nav__container').forEach((c) => {
          // console.log(c);
          
            c.classList.add('nav--scrolled');
        });
        
    } else {
        document.querySelectorAll('.nav__container').forEach((c) => {
            c.classList.remove('nav--scrolled');
        });
        
    }
}
scrollTo(id: string){
  let el = document.getElementById(id);
  if(el){ 
    // let y = el.getBoundingClientRect().top + window.pageYOffset;
    // console.log(y);
    console.log(el);
    
    el.scrollIntoView({behavior: 'smooth', block: 'start'});
    // el.scrollTo({top: y, behavior: 'smooth'});
  }
  
}

}
