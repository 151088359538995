import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-us-full-content',
  templateUrl: './why-us-full-content.component.html',
  styleUrls: ['./why-us-full-content.component.css']
})
export class WhyUsFullContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
}
