<section ngClass="meet-her__container" fxLayout="column" fxLayoutAlign="center center">
    <div id="meet-her" style="position: absolute; top: -10rem; left: 0"></div>
    <div ngClass="meet-her__sub-container" ngClass="" fxLayout="column" fxLayoutAlign="center center">
        <h1 ngClass="h1--xs">Conoce a tu administradora</h1>
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="center center">
            <img 
            ngClass="meet-her__img" ngClass.lt-md="meet-her__img meet-her__img--lt-md"
             src="../../assets/img/AboutMeImg-removebg-preview.png" alt="">
            <div>
                <p ngClass="meet-her__description"
                ngClass.xs="meet-her__description--xs">
                Soy Rosa Rodríguez, licenciada en contabilidad, CPA, con postgrado en administración de 
                empresas en la universidad de Oberta de Catalunya, España. He realizado diplomados en 
                planificación estratégica, Gestión Humana, finanzas, derecho laboral, actualización en 
                cumplimiento en materia de lavado de activos, administración de propiedades, servicio al 
                cliente, supervisión de gobernanza en cooperativas, habilitación docente, entre otros. Tengo
                 18 años de experiencia en puestos gerenciales a nivel administrativo, contable, financiero y 
                 de gestión. Con enfoque en planificación estratégica para creación y desarrollo de PYMES, en 
                 dirección, negocios y organización de empresas.
                </p>
                <div>

                </div>
            </div>
        </div>
    </div>
    
</section>
