import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-full-content',
  templateUrl: './services-full-content.component.html',
  styleUrls: ['./services-full-content.component.css']
})
export class ServicesFullContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0,0);
  }

}
